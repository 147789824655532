import React from "react";
import { Card, Container } from "react-bootstrap";

import Image from "next/image";

import select1_dark from "../../../public/img/step1-build-digital-wallet-dark.png";
import select2_dark from "../../../public/img/step2-develop-collections-dark.png";
import select4_dark from "../../../public/img/step4-list-nft-for-sale-dark.png";
import select5_dark from "../../../public/img/step5-add-nfts-dark.png";

import styles from "./Rewording.module.scss";

function index() {
  
  return (
    <div className="section">
      {/* {t('common:nft_steps_title')} */}

      <Container>
        <h4 className={`h3 ${styles.title}`}>
        What is an NFT
        </h4>
        <Card.Text className={styles.textHeading}>
        An NFT (non-fungible token) is a one-of-a-kind digital asset stored on a blockchain. Unlike cryptocurrencies such as Bitcoin or Ethereum, which are fungible and interchangeable, each NFT has unique characteristics that distinguish it from other tokens. NFTs can represent a wide range of digital content, including artwork, music, videos, virtual real estate, collectibles, and more. They serve as a digital record of ownership, providing proof of authenticity.
        </Card.Text>

        <h4 className={`h4 ${styles.title} mt-5`}>
        Creating an NFT on 21Spades is fast and easy:
        </h4>
        <Card.Text className={styles.textHeading}>
        {/* <strong>Creating an NFT on 21Spades is fast and easy:</strong> */}
        </Card.Text>

        <div className={`row ${styles.titlerow} flex-wrap-reverse mt-2`}>
          <div className="col-12 col-sm-6">
            <div className={` ${styles.darkImgs} d-flex justify-content-start`}>
              <Image
                src={select1_dark}
                width={300}
                height={300}
                alt="Picture of the author"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
          <Card.Text className={styles.text}>
          <h2 className={`h4 ${styles.title}`}> Set up a Crypto Wallet </h2>
           {`You'll need a crypto wallet to create and mint your NFT. This wallet acts as your key to interact with decentralized apps and perform transactions. 21Spades is compatible with a wide variety of wallets.`}
          </Card.Text>
          </div>
        </div>

        <div className={`row ${styles.titlerow}`}>
          <div className="col-12 col-sm-6">
          <Card.Text className={styles.text}>
          <h2 className={`h4 ${styles.title}`}> Choose a Blockchain </h2>
           21Spades is currently compatible with the Avalanche blockchain for minting NFTs. Creators will need enough cryptocurrency (AVAX) in their wallets to cover the gas fees for the blockchain transaction.
            </Card.Text>
          </div>
          <div className="col-12 col-sm-6">
            <div className={`${styles.darkImgs} d-flex justify-content-end`}>
              <Image
                src={select5_dark}
                width={300}
                height={300}
                alt="Picture of the author"
              />
            </div>
          </div>
        </div>

        <div className={`row ${styles.titlerow} flex-wrap-reverse`}>
          <div className="col-12 col-sm-6">
            <div className={`${styles.darkImgs} d-flex justify-content-start`}>
              <Image
                src={select2_dark}
                width={300}
                height={300}
                alt="Picture of the author"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
          <Card.Text className={styles.text}>
          <h2 className={`h4 ${styles.title}`}> Create your Collection </h2>
          {`Decide the purpose and genre of your collection. Whether it's artwork, music, videos, or something else entirely, knowing your focus is essential. Now the fun begins! Name your collection, describe it, and choose a category that best represents your work. You can also provide relevant links to social media.`}
          </Card.Text>
          </div>
        </div>

        <div className={`row ${styles.titlerow}`}>
          <div className="col-12 col-sm-6">
          <Card.Text className={styles.text}>
          <h2 className={`h4 ${styles.title}`}> Mint your NFTs </h2>
           {`Once your wallet is set up, the blockchain is chosen, and your collection is created, you're ready to mint your NFTs. Minting establishes the digital item's immutable record of authenticity and ownership on the blockchain.`}
         </Card.Text>
          
          </div>
          <div className="col-12 col-sm-6">
            <div className={` ${styles.darkImgs} d-flex justify-content-end`}>
              <Image
                src={select4_dark}
                width={300}
                height={300}
                alt="Picture of the author"
              />
            </div>
          </div>
        </div>

      </Container>
    </div>
  );
}

export default index;
